import {
  preformatMakeCredReq,
  preformatGetAssertReq,
  publicKeyCredentialToJSON,
} from './utils';

const webAuthnEndpointMapping = {
  'https://www.finid.com.au': 'https://api.finid.com.au',
  'http://localhost:3000': 'http://localhost:3000',
};
const webAuthnEndpoint = webAuthnEndpointMapping[window.origin];

const webAuthnRegister = async ({displayName, username}) => {

  const registrationPayload = {
    username,
    displayName,
  }

  const registrationResponse = await fetch(`${webAuthnEndpoint}/v1/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(registrationPayload),
  });
  const registrationJSON = await registrationResponse.json();

  if (registrationJSON.status === 'failed') {
    return registrationJSON;
  }

  const sessionJWT = registrationJSON.sessionJWT;

  let publicKey = preformatMakeCredReq(registrationJSON.credRequest);
  const credentials = await navigator.credentials.create({ publicKey });

  let makeCredResponse = publicKeyCredentialToJSON(credentials);

  /*
   * Challenge Response
   */
  const challengeResponseResponse = await fetch(`${webAuthnEndpoint}/v1/response`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${sessionJWT}`,
    },
    body: JSON.stringify(makeCredResponse),
  });
  const challengeResponseJSON = await challengeResponseResponse.json();

  // at this point we should have the new JWT with "is logged in" set to true
  localStorage.setItem("session", challengeResponseJSON.sessionJWT);

  const result = challengeResponseJSON.status === 'ok'
    ? {
      'status': 'ok',
      'message': `User ${username} was enrolled successfully`,
    } : {
      'status': 'failed',
      'message': `Unable to enrol username ${username}: ${challengeResponseJSON.message}`,
    };

  return result;
}

const webAuthnLogin = async ({ username }) => {

  const loginPayload = {
    username,
  }

  const loginResponse = await fetch(`${webAuthnEndpoint}/v1/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(loginPayload),
  });
  const loginJSON = await loginResponse.json();

  if (loginJSON.status === 'failed') {
    return loginJSON;
  }

  const sessionJWT = loginJSON.sessionJWT;

  const publicKey = preformatGetAssertReq(loginJSON.assertRequest)
  const credentials = await navigator.credentials.get({ publicKey });
  const getAssertionResponse = publicKeyCredentialToJSON(credentials);

  /*
   * Challenge Response
   */
  const challengeResponseResponse = await fetch(`${webAuthnEndpoint}/v1/response`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${sessionJWT}`,
    },
    body: JSON.stringify(getAssertionResponse),
  });
  const challengeResponseJSON = await challengeResponseResponse.json();

  // at this point we should have the new JWT with "is logged in" set to true
  localStorage.setItem("session", challengeResponseJSON.sessionJWT);

  const result = challengeResponseJSON.status === 'ok'
    ? {
      'status': 'ok',
      'message': `User ${username} was logged in successfully`,
    } : {
      'status': 'failed',
      'message': `Unable to log in ${username}: ${challengeResponseJSON.message}`,
    };

  return result;
}

export {
  webAuthnRegister,
  webAuthnLogin
};
