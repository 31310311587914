/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// WebAuthn helper module
import { webAuthnRegister } from '../../webauthn/webauthn';

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

const Register = () => {
  /*
   *  State variables
   */
  const [preferredName, setPreferredName] = useState('');
  const [personalEmail, setPersonalEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  /*
   *  Registration action
   */
  const registerUser = async ({ displayName, username }) => {

    if (username === '') {
      setErrorMessage('Personal Email cannot be empty');
    } else {
      setLoading(true);

      const response = await webAuthnRegister({ username, displayName });
      console.log(response);
      if (response.status === 'ok') {

        // Redirect user to profile page
        window.location.href = '/profile';
      } else {
        setErrorMessage(response.message);
        setLoading(false);
      }

    }
  }
  return (
    <>
      <DemoNavbar />
      <main>
        <section className="section section-shaped section-lg">
          <div className="shape shape-style-1 bg-gradient-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Container className="pt-lg-7">
            <Row className="justify-content-center">
              <Col lg="5">
                <Card className="bg-secondary shadow border-0">
                  <CardHeader className="bg-white">
                    <div className="text-muted text-center mb-3">
                      <small>Register with Biometric Authentication</small>
                    </div>
                    <div className="text-center">

                      <i className="fas fa-fingerprint fa-3x" />
                    </div>
                  </CardHeader>
                  <CardBody className="px-lg-5 pb-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>No Password Required</small>
                    </div>
                    {errorMessage === ''
                      ? null
                      : <p className="text-center text-danger">{errorMessage}</p>
                    }
                    <Form role="form">
                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-circle-08" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Preferred Name"
                            type="text"
                            onChange={e => setPreferredName(e.target.value)}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Personal Email"
                            type="email"
                            onChange={e => setPersonalEmail(e.target.value)}
                          />
                        </InputGroup>
                      </FormGroup>
                      <div className="text-muted font-italic">
                        <small>
                          security strength:{" "}
                          <span className="text-success font-weight-700">
                            better-than-bank grade
                          </span>
                        </small>
                      </div>
                      <br />
                      {/*
                      <Row className="my-4">
                        <Col xs="12">
                          <div className="custom-control custom-control-alternative custom-checkbox">
                            <input
                              className="custom-control-input"
                              id="customCheckRegister"
                              type="checkbox"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheckRegister"
                            >
                              <span>
                                I agree with the{" "}
                                <a
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Privacy Policy
                                </a>
                              </span>
                            </label>
                          </div>
                        </Col>
                      </Row>
                      */}
                      <div className="text-center">
                        <Button
                          className="mt-4"
                          color="primary"
                          type="button"
                          onClick={() => registerUser({
                            displayName: preferredName,
                            username: personalEmail,
                          })}
                          disabled={loading ? true : false}
                        >
                          {loading
                            ? <>
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-circle-notch fa-spin" />
                              </span>
                              <span className="btn-inner--text">
                                Magic At Work
                              </span>
                            </>
                            : <>
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-fingerprint" />
                              </span>
                              <span className="btn-inner--text">
                                Create account
                              </span>
                            </>
                          }
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <SimpleFooter />
    </>
  );
}

export default Register;
